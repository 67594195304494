<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>阿根廷比索充值</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main v-loading.fullscreen.lock="loading">
              <el-result icon="success" title="订单已完成"
                ><template slot="extra">
                  <span style="color: #DC143C">礼物卡已赠送，请在14天内接收礼物卡。超时无法处理！！！</span>
                </template>
              </el-result>
            </el-main>
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
    <script>
export default {
  name: "complete",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      showTable: false,
      remainder: "",
      form: {
        tbOrderId: "",
        steamHomeUrl: "",
      },
      queryForm: {
        queryTbOid: "",
      },
      dataRule: {
        tbOrderId: [
          { required: true, message: "淘宝订单号不能为空", trigger: "blur" },
        ],
        steamHomeUrl: [
          { required: true, message: "steam个人链接不能为空", trigger: "blur" },
        ],
      },
      queryRule: {
        queryTbOid: [
          { required: true, message: "淘宝订单号不能为空", trigger: "blur" },
        ],
      },
      tableData: [],
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
    setInterval(this.showtime, 1000); //反复执行函数本身
  },
  created() {
    if (this.$route.query.tbOid) {
      this.form.tbOrderId = this.$route.query.tbOid;
      this.queryForm.queryTbOid = this.$route.query.tbOid;
    }
  },
  methods: {
    showtime: function () {
      var nowtime = new Date(); //获取当前时间
      var endtime = new Date("2023/8/8"); //定义结束时间
      var lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
      console.log(leftd + "天" + lefth + ":" + leftm + ":" + lefts);
      this.remainder =
        leftd + "天" + lefth + "时" + leftm + "分" + lefts + "秒"; //返回倒计时的字符串
    },
    openNewPage: function (fileName) {
      console.log(this.queryForm.queryTbOid);
      window.open(
        "https://guoguo.cpu88.cn/point-image/" +
          this.queryForm.queryTbOid +
          fileName +
          ".html"
      );
    },
    receiptDateFormat: function (row, column) {
      if (row[column.property]) {
        var sendTime = new Date(row[column.property]);
        if (sendTime == undefined) {
          return "";
        }
        return this.$moment(sendTime.setDate(sendTime.getDate() + 14)).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    },
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {},
    handleClick(tab, event) {},
    extractGift() {
      this.$refs["form"].validate((valid) => {
        // if (valid) {
        //   this.loading = true;
        //   this.$http
        //     .post("guest/gift/exchange", this.form)
        //     .then(({ data }) => {
        //       if (data && data.code === 0) {
        //         this.loading = false;
        //         this.$message({
        //           message: "操作成功",
        //           type: "success",
        //           duration: 1500,
        //         });
        //       } else {
        //         this.loading = false;
        //         this.$message.error(data.msg);
        //       }
        //     })
        //     .catch((e) => {
        //       this.loading = false;
        //       this.$message({
        //         message: "网络或程序异常！" + e,
        //         type: "error",
        //       });
        //     });
        // }
        this.showtime();
      });
    },
    queryProgress() {
      this.tableData = [];
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          this.$http
            .get("guest/gift/queryProgress/" + this.queryForm.queryTbOid)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.showTable = true;
                this.tableData.push(data.progress);
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
    
    <style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
        margin-bottom: 40px;
      } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
    