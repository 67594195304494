import Vue from 'vue'
import Router from 'vue-router'
import Index from '../components/index'
import SubmitLink from '../components/submit-link'
import WaitCd from '../components/wait-cd'
import Complete from '../components/complete'
import Pay from '../components/pay'



Vue.use(Router)
const routes = [
  { path: '/', component: Index, name: 'idnex' },

  { path: '/submit-link', component: SubmitLink, name: 'submit-link' },
  { path: '/wait-cd', component: WaitCd, name: 'wait-cd' },
  { path: '/complete', component: Complete, name: 'complete' },
  { path: '/pay', component: Pay, name: 'pay' },


]
const router = new Router({
    mode: 'hash',
    routes,
    scrollBehavior: () => ({ y: 0 }),
    isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  })
export default router
