<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>阿根廷比索充值</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content2" v-loading.fullscreen.lock="loading">
            <el-row>
              <el-col>
                <div @click="extractGift('alipay')" class="payWrapper">
                  <img
                    src="../assets/alipay.png"
                    alt="支付宝支付"
                    class="payIcon"
                  /><span class="payTitle">支付宝支付</span>
                </div>
              </el-col>
              <el-col>
                <div @click="extractGift('wechat')" class="payWrapper">
                  <img
                    src="../assets/wechat.png"
                    alt="微信支付"
                    class="payIcon"
                  /><span class="payTitle">微信支付</span>
                </div>
              </el-col>
            </el-row>
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
    <script>
export default {
  name: "complete",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      showTable: false,
      remainder: "",
      form: {
        tbOid: "",
        paymentMethod: "",
      },
      dataRule: {
        tbOrderId: [
          { required: true, message: "淘宝订单号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 8;
      this.offsetValue = 8;
    }
  },
  created() {
    if (this.$route.query.tbOid) {
      this.form.tbOid = this.$route.query.tbOid;
    }
  },
  methods: {
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    extractGift(paymentMethod) {
      this.loading = true;
      this.form.paymentMethod = paymentMethod;
      this.$http
        .post("guest/gift/pay", this.form)
        .then(({ data }) => {
          if (data && data.code === 0) {
            this.loading = false;
            var newWin = window.open("");
            newWin.document.write(data.payHtml);
          } else {
            this.loading = false;
            this.$message.error(data.msg);
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$message({
            message: "网络或程序异常！" + e,
            type: "error",
          });
        });
    },
    queryProgress() {
      this.tableData = [];
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          this.$http
            .get("guest/gift/queryProgress/" + this.queryForm.queryTbOid)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.showTable = true;
                this.tableData.push(data.progress);
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
    
    <style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content2 {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 100px 100px 100px 130px;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
        margin-bottom: 40px;
      } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
.payIcon {
  width: 50px;
  height: 50px;
}

.payWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #666;
  cursor: pointer;
  padding: 10px;
  border-radius: 4px;
  transition: all 0.25s ease;
  font-size: 14px;
  margin-top: 20px;
}

.payWrapper .payTitle {
  font-size: 16px;
}

.payWrapper:hover {
  border-color: #6495ed;
  color: #6495ed;
}

.payTitle {
  font-size: 18px;
  letter-spacing: 2px;
  display: inline-block;
  margin-left: 20px;
  transition: all 0.25s ease;
}

.payWechatQr {
  display: block;
  margin: 10px auto;
  width: 200px;
}

.payLink {
  display: block;
  margin: 10px auto;
  padding: 10px;
  text-align: center;
  font-size: 18px;
}
</style>
    