<template>
  <el-container>
    <el-header height="180px"></el-header>
    <el-main>
      <el-row>
        <el-col>
          <h2>阿根廷比索充值</h2>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="spanValue" :offset="offsetValue">
          <el-container id="content">
            <el-main v-loading.fullscreen.lock="loading">
              <el-alert title="欢迎使用阿根廷比索充值" type="warning">
              </el-alert>
              <br />
              <br />
              <el-form ref="form" :model="form" :rules="dataRule">
                <div style="margin-bottom: 20px; float: left">淘宝订单号</div>
                <el-form-item prop="tbOrderId">
                  <el-input
                    v-model="form.tbOrderId"
                    placeholder="请输入淘宝订单号"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="extractGift()"
                    >下一步</el-button
                  >
                </el-form-item>
              </el-form></el-main
            >
          </el-container>
        </el-col>
      </el-row>
      <div class="empty"></div>
    </el-main>
  </el-container>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      spanValue: 0,
      offsetValue: 0,
      activeName: "first",
      loading: false,
      showTable: false,
      form: {
        tbOrderId: "",
      },
      dataRule: {
        tbOrderId: [
          { required: true, message: "淘宝订单号不能为空", trigger: "blur" },
        ],
      },
    };
  },
  mounted: function () {
    if (this._isMobile()) {
      this.spanValue = 24;
      this.offsetValue = 0;
    } else {
      this.spanValue = 10;
      this.offsetValue = 7;
    }
  },
  created() {
    if (this.$route.query.tbOid) {
      this.form.tbOrderId = this.$route.query.tbOid;
    }
  },
  methods: {
    // 判断移动端还是pc端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    onSubmit() {},
    extractGift() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("guest/gift/getStatus", this.form)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.loading = false;
                if (data.order.status === 1) {
                  this.$router.push({
                    path: "/submit-link",
                    query: {
                      tbOid: this.form.tbOrderId,
                    },
                  });
                } else if (data.order.status === 2) {
                  this.$router.push({
                    path: "/wait-cd",
                    query: {
                      tbOid: this.form.tbOrderId,
                    },
                  });
                } else if (data.order.status === 3) {
                  this.$router.push({
                    path: "/pay",
                    query: {
                      tbOid: this.form.tbOrderId,
                    },
                  });
                } else if (data.order.status === 4) {
                  this.$router.push({
                    path: "/complete",
                    query: {
                      tbOid: this.form.tbOrderId,
                    },
                  });
                } else {
                  this.$message.error("商家正在处理中...");
                }
              } else {
                this.loading = false;
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.loading = false;
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
    queryProgress() {
      this.tableData = [];
      this.$refs["queryForm"].validate((valid) => {
        if (valid) {
          this.$http
            .get("guest/gift/queryProgress/" + this.queryForm.queryTbOid)
            .then(({ data }) => {
              if (data && data.code === 0) {
                this.showTable = true;
                this.tableData.push(data.progress);
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((e) => {
              this.$message({
                message: "网络或程序异常！" + e,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style>
body {
  background-image: url("../assets/background.jpg");
}
.el-header,
.el-footer {
  text-align: center;
  line-height: 80px;
}

.el-main {
  color: #b3c0d1;
  text-align: center;
}
#content {
  min-height: 230px;
  height: auto;
  box-sizing: border-box;
  background-color: #181a21;
  padding: 34px 42p;
}
.el-main span {
  text-align: left;
}
#main-col {
  background-color: #181a21;
  height: 250px;
}
/* body > .el-container {
    margin-bottom: 40px;
  } */

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
el-card {
  padding: 0px;
}
.empty,
el-footer {
  height: 100px;
}
</style>
